
.index {
  box-sizing: border-box;
  height: 100%;
  // padding: 20px 20px 0;
}
.resources-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.goods-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  .goods-cover {
    width: 44px;
    height: 44px;
    display: flex;
    // background: rgba(64, 66, 109, 0.8) !important;
    border-radius: 4px;
    img {
      width: 100%;
      height: 100%;
     object-fit: cover;
    }
  }
  .goods-name {
    //   width: 1%;
    //   flex: 1;
    margin-left: 10px;
    font-size: 14px;
  }
}
.scro {
  height: 100%;
}
.pages-center {
  // margin: 20px 0 20px;
  text-align: center;
}
::v-deep .customTable th:first-child > .cell {
  padding-left: 30px;
}
::v-deep .customTable td:first-child > .cell {
  padding-left: 30px;
}
::v-deep .customTable th.el-table__cell {
  background: #40426d !important;
  color: #fff !important;
  height: 50px;
}
::v-deep .el-table__body-wrapper {
  background: #101349;
}
::v-deep .customTable td.el-table__cell {
  border-bottom: 1px solid #eeeeee;
  height: 30px;
  padding: 10px 0 !important;
}
::v-deep .el-table .el-table__cel{
    padding: 10px 0 !important;
}
::v-deep .el-table tr {
  background: #101349;
  color: #fff !important;
}
::v-deep
  .customTable.el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background: rgba(64, 66, 109, 0.7) !important;
  color: #fff !important;
}
::v-deep .pages-center .el-pager .active {
  background: #101349;
  color: #409EFF !important;
}
::v-deep .el-input__inner {
 
  color: #fff !important;
  background: rgba(75, 69, 255, 0.1);
  border-radius: 2px 2px 2px 2px;
  border: 1px solid rgba(75, 69, 255, 0.2);
}
::v-deep .el-pagination__jump{
    color: #fff !important;  
}
/* 自定义 el-table 滚动条样式 */
.el-table__body-wrapper::-webkit-scrollbar {
  width: 5px; /* 滚动条宽度 */
  height: 5px; /* 横向滚动条的高度 */
}
::v-deep ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #40426d;
}
::v-deep ::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   background-color: rgba(16, 19, 73,.3);
  background-color: #5d58ff;
  border-radius: 10px;

}
::v-deep ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background: #40426d;
 
}


