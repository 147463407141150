
.other-live-room {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 30px;
  overflow: hidden;
 background: #101349;
  .operate {
    margin-bottom: 20px;
    // text-align: end;
    display: flex;
    justify-content: space-between;
    .table_list{
      color: #FFF;
      display: flex;
      .list_item{
        width: 90px;
        height: 40px;
        background: rgba(75,69,255,0.1);
        color: rgba(255,255,255,0.6);
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        line-height: 40px;
        margin-right: 10px;
        cursor: pointer;
      }
      .active_item{
        background: rgba(75,69,255,0.4);
        color: #ffffff;
      }
    }
    .back {
      position: relative;
      width: 90px;
      height: 40px;
      color: #fff;
      font-size: 16px;
      border: none;
      background-color: #4b45ff;
      border-radius: 4px;
      cursor: pointer;
      &:hover::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
  .content {
    box-sizing: border-box;
    flex: 1;
    overflow: hidden;
    .room-list {
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(auto-fill, 250px);
      row-gap: 40px;
      column-gap: 20px;
      justify-content: space-between;
      padding-right: 1px;
      .room-item {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 250px;
        height: 300px;
        border-radius: 20px;
        overflow: hidden;
        border: 1px solid transparent;
        cursor: pointer;
        &:hover {
          border-color: #4b45ff;
        }
        .head {
          display: flex;
          align-items: flex-end;
          width: 100%;
          height: 204px;
          background-image: url("https://tse1-mm.cn.bing.net/th/id/R-C.35bcc4c4b02ffbfba073b807044b6e27?rik=gjZqaq%2b6HSBX2A&riu=http%3a%2f%2f5b0988e595225.cdn.sohucs.com%2fimages%2f20200505%2f2ea8ba335e9f4f3eac817338cfc5f3e0.jpeg&ehk=MG1RvptD6F%2bZNf6%2fMmXafOu6AlO6oe1qQIv35X2xd%2fE%3d&risl=&pid=ImgRaw&r=0");
          background-size: 100% 100%;
          .live-base-info {
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 50px;
            padding: 0 10px;
            background-color: rgba(0, 0, 0, 0.5);
            .people-num {
              display: flex;
              align-items: center;
              color: #fff;
              font-size: 16px;
              .icon {
                margin-right: 10px;
                font-size: 20px;
              }
            }
            .state-box {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 80px;
              height: 26px;
              font-size: 14px;
              border-radius: 4px;
              .icon {
                margin-right: 4px;
                font-size: 12px;
              }
            }
            .live-ing {
              color: #fff;
              background-color: #fb0f4b;
            }
            .no-start,
            .live-end {
              color: #5b5b6d;
              background-color: #cfd1db;
            }
          }
        }
        .live-info {
          flex: 1;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 0 13px;
          color: #fff;
          .live-title {
            margin-bottom: 10px;
            font-size: 16px;
          }
          .user-info {
            display: flex;
            align-items: center;
            .avatar {
              width: 24px;
              height: 24px;
              margin-right: 9px;
              border-radius: 50%;
              .avatar-img {
                width: 100%;
                height: 100%;
                vertical-align: middle;
              }
            }
            .username {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
::v-deep .pages-center{
  background: none;
  padding: 0px 0px !important;
  margin-top: 10px;
}
::v-deep .el-pagination button:disabled{
      background: none;
          color: #909399
}
::v-deep .el-pagination .btn-next{
   background: none;
      color: #fff
}
::v-deep .el-pagination .btn-prev{
    background: none;
          color: #fff
}
::v-deep .el-pager li{
    background: none;
    color: #fff !important;
}
::v-deep .pages-center .el-pager .active {
  background: #101349;
  color: #409EFF !important;
}
::v-deep .el-input__inner {
 
  color: #fff !important;
  background: rgba(75, 69, 255, 0.1);
  border-radius: 2px 2px 2px 2px;
  border: 1px solid rgba(75, 69, 255, 0.2);
}
::v-deep .el-pagination__jump{
    color: #fff !important;  
}
